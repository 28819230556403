var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mwc-markets-watchlist", {
    attrs: { "mwc-id": _vm.mwcIds, skin: _vm.skin, config: _vm.mergedConfigs }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }