(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mwc-markets-core"), require("mwc-markets-watchlist"));
	else if(typeof define === 'function' && define.amd)
		define(["mwc-markets-core", "mwc-markets-watchlist"], factory);
	else if(typeof exports === 'object')
		exports["mwcMarketsCommodity"] = factory(require("mwc-markets-core"), require("mwc-markets-watchlist"));
	else
		root["mwcMarketsCommodity"] = factory(root["mwcMarketsCore"], root["mwcMarketsWatchlist"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__61__) {
return 