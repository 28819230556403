<template>
    <mwc-markets-watchlist
        :mwc-id="mwcIds"
        :skin="skin"
        :config="mergedConfigs"
    >
    </mwc-markets-watchlist>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import 'mwc-markets-watchlist';
const { mixins, utils } = mwcMarketsCore;
export default {
    name: 'mwc-markets-commodity',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    data() {
        return {
            defaultConfig: {
                settings: {
                    autoHeight: true,
                    showSetting: false,
                    showGroup: false,
                    symbol:
                        '64.3.@ZC0Y,64.3.@ZS0Y,64.3.@ZM0Y,64.3.@ZL0Y,64.3.@ZW0Y,68.3.CC0Y,80.3.CT0Y,68.3.KC0Y,68.3.SB0Y,67.3.@CL0Y,156.3.BRN0Y,67.3.@RB0Y,67.3.@NG0Y,67.3.@HO0Y,77.3.@LE0Y,77.3.@GF0Y,77.3.@HE0Y,66.3.@GC0Y,66.3.@SI0Y,66.3.@HG0Y,67.3.@PL0Y',
                    columnType: 'basic',
                    columnSets: [
                        {
                            id: 'basic',
                            dataPoints: [
                                'name',
                                'lastPrice',
                                'chg',
                                'chg%',
                                'expirationDate',
                                'tradeDate',
                                'tradeTime'
                            ]
                        }
                    ],
                    groupKey: 'groupName',
                    customRows: [
                        {
                            id: '67.3.@CL0Y',
                            name: 'WTI Crude',
                            groupName: 'Energy'
                        },
                        {
                            id: '156.3.BRN0Y',
                            name: 'Brent Crude',
                            groupName: 'Energy'
                        },
                        {
                            id: '67.3.@RB0Y',
                            name: 'RBOB Gasoline',
                            groupName: 'Energy'
                        },
                        {
                            id: '67.3.@NG0Y',
                            name: 'Natural Gas',
                            groupName: 'Energy'
                        },
                        {
                            id: '67.3.@HO0Y',
                            name: 'Heating Oil',
                            groupName: 'Energy'
                        },
                        { id: '66.3.@GC0Y', name: 'Gold', groupName: 'Metals' },
                        {
                            id: '66.3.@SI0Y',
                            name: 'Silver',
                            groupName: 'Metals'
                        },
                        {
                            id: '66.3.@HG0Y',
                            name: 'Copper',
                            groupName: 'Metals'
                        },
                        {
                            id: '67.3.@PL0Y',
                            name: 'Platinum',
                            groupName: 'Metals'
                        },
                        {
                            id: '64.3.@ZS0Y',
                            name: 'Soybean',
                            groupName: 'Agriculture'
                        },
                        {
                            id: '64.3.@ZM0Y',
                            name: 'Soybean Meal',
                            groupName: 'Agriculture'
                        },
                        {
                            id: '64.3.@ZL0Y',
                            name: 'Soybean Oil',
                            groupName: 'Agriculture'
                        },
                        {
                            id: '64.3.@ZW0Y',
                            name: 'Wheat',
                            groupName: 'Agriculture'
                        },
                        {
                            id: '68.3.CC0Y',
                            name: 'Cocoa',
                            groupName: 'Agriculture'
                        },
                        {
                            id: '80.3.CT0Y',
                            name: 'Cotton',
                            groupName: 'Agriculture'
                        },
                        {
                            id: '68.3.KC0Y',
                            name: 'Coffee',
                            groupName: 'Agriculture'
                        },
                        {
                            id: '68.3.SB0Y',
                            name: 'Sugar',
                            groupName: 'Agriculture'
                        },
                        {
                            id: '77.3.@LE0Y',
                            name: 'Live Cattle',
                            groupName: 'Livestock'
                        },
                        {
                            id: '77.3.@GF0Y',
                            name: 'Feeder Cattle',
                            groupName: 'Livestock'
                        },
                        {
                            id: '77.3.@HE0Y',
                            name: 'Lean Hogs',
                            groupName: 'Livestock'
                        },
                        {
                            id: '64.3.@ZC0Y',
                            name: 'Corn',
                            groupName: 'Agriculture'
                        }
                    ],
                    enableGroup: true,
                    customGroupDataPoints: [
                        {
                            id: 'groupName',
                            label: 'Custom',
                            points: ['groupName']
                        }
                    ]
                }
            }
        };
    },
    computed: {
        mergedConfigs() {
            // The getComponentConfig method mixin form MwcVueHelperMixin.
            return JSON.stringify(this.getComponentConfig());
        },
        mwcIds() {
            return `mwc-markets-watchlist-${utils.guid()}`;
        }
    },
    mounted() {
        [
            'settings-changed',
            'row-double-click',
            'hover-menu-click',
            'render-completed',
            'track',
            'error'
        ].forEach(eventName => {
            this.$el.addEventListener(eventName, e => {
                if (eventName === 'track') {
                    const args = e.detail[0];
                    args.componentType = 'mwc-markets-commodity';
                    this.trackEvent(args);
                } else if (eventName === 'row-double-click') {
                    this.$emit(eventName, this.extendSecurity(e));
                } else if (eventName === 'hover-menu-click') {
                    this.$emit(eventName, this.extendSecurity(e), e.detail[1]);
                } else {
                    this.$emit(eventName, e ? e.detail[0] : undefined);
                }
            });
        });
    },
    methods: {
        extendSecurity(e) {
            const security = e.detail[0];
            const row = utils.find(
                this.settings.customRows,
                r => r.id === security.queryKey
            );
            return utils.extend(true, security, {
                name: row ? row.name : null
            });
        }
    }
};
</script>
